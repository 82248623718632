<template>
  <!-- Start Copy Right Area  -->
  <div class="copyright-area copyright-style-one">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-8 col-sm-12 col-12">
          <div class="copyright-left">
            <ul class="ft-menu link-hover">
              <li><span style="    font-size: 14px;">本网站所有资源来源网络，再次感谢西安富力为广大业主提供资料，如有侵权请联系业主代表删除！</span>
                <router-link to="/contact">联系我们</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-4 col-sm-12 col-12">
          <div class="copyright-right text-center text-lg-end">
            <p class="copyright-text">©这里是开远城 {{ new Date().getFullYear() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Copy Right Area  -->
</template>

<script>
export default {
  name: 'Copyright'
}
</script>
<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <iframe src="https://j.map.baidu.com/22/MUD" width="600" height="550" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
    export default {
        name: 'GoogleMap'
    }
</script>
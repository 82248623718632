<template>
  <ul class="mainmenu">
    <li class="has-droupdown has-menu-child-item">
      <a href="/">
        首页
        <Icon name="plus" class="collapse-icon plus"/>
        <Icon name="minus" class="collapse-icon minus"/>
      </a>
    </li>
    <li class="with-megamenu has-menu-child-item">
      <a href="/#buildprogress">
        业主服务
        <Icon name="plus" class="collapse-icon plus"/>
        <Icon name="minus" class="collapse-icon minus"/>
      </a>
      <div class="rn-megamenu">
        <div class="wrapper">
          <div class="row row--0">
            <div class="single-mega-item">
              <ul class="mega-menu-item">
                <li>
                  <a href="/#buildprogress">
                    施工进度 <span class="rn-badge-card">热门</span>
                  </a>
                </li>
                <li>
                  <router-link to="#">
                    物业服务 <span class="rn-badge-card">开发中</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="#">
                    生活服务 <span class="rn-badge-card">开发中</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="#">
                    其他
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="has-droupdown has-menu-child-item">
      <a href="#">
        户型鉴赏
        <Icon name="plus" class="collapse-icon plus"/>
        <Icon name="minus" class="collapse-icon minus"/>
      </a>
      <ul class="submenu">
        <li>
          <a href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzI4NTQxMDg1Ng==&action=getalbum&album_id=2503386162040946689&scene=173&from_msgid=2247484940&from_itemidx=2&count=3&nolastread=1#wechat_redirect" target="_black">户型图合集</a>
        </li>
        <li>
          <a href="https://fulihaofang.ideamake.cn/icp/fulikaiyuancheng_1078/index.html#" target="_black">开远城云端微世界</a>
        </li>
        <li>
          <a href="https://infishow.ideamake.cn/icp/fulikaiyuankejiguanquanjingshipai_12241/index.html#"
             target="_black">开远城科技馆实拍</a>
        </li>
        <!--        <li>-->
        <!--          <router-link to="/portfolio">A区户型图</router-link>-->
        <!--        </li>-->
      </ul>
    </li>
    <li class="has-droupdown has-menu-child-item">
      <a href="/#news">
        文章公告
        <Icon name="plus" class="collapse-icon plus"/>
        <Icon name="minus" class="collapse-icon minus"/>
      </a>
    </li>
    <li>
      <router-link to="/about">关于我们</router-link>
    </li>
    <li>
      <router-link to="/contact">联系我们</router-link>
    </li>
  </ul>
</template>

<script>
import Icon from "../../icon/Icon";

export default {
  name: 'Nav',
  components: {Icon}
}
</script>
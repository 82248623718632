<template>
  <router-view/>
</template>

<script>
export default {}
</script>
<style>

.flip-card {
  display: block !important;
  position: relative !important;
  padding-bottom: 0.72em !important;
  font-size: 5.25rem !important;
  line-height: 0.95 !important;
}

.flip-clock {
  color: #b9b9b9 !important;
}

@media (min-width: 1000px) {
  .flip-clock__slot {
    font-size: 2.2rem !important;
  }

  .flip-card {
    font-size: 5rem !important;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px !important;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1400px !important;
  }
}


.flip-card__top, .flip-card__bottom, .flip-card__back-bottom, .flip-card__back::before, .flip-card__back::after {
  width: 2.5em !important;
}


</style>
<template>
  <div>
    <!-- Start Footer Area  -->
    <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two">
      <div v-if="data.showCta" class="rn-callto-action clltoaction-style-default style-7">
        <div class="container">
          <div class="row row--0 align-items-center content-wrapper">
            <div class="col-lg-8 col-md-8">
              <div class="inner" data-aos="fade-up">
                <div class="content text-left">
                  <Logo/>
                  <p class="subtitle">
                    春有百花秋望月，夏有凉风冬听雪，世间所有的美好总会如期而至的，保持一颗充满惊喜的心，一切都会与众不同。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="call-to-btn text-start mt_sm--20 text-lg-end" data-aos="fade-up">
                <Button
                    title="联系我们"
                    icon="arrow-right"
                    url="/contact"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <h4 class="title">业主服务</h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li>
                      <router-link to="/">物业服务</router-link>
                    </li>
                    <li>
                      <router-link to="/">生活服务</router-link>
                    </li>
                    <li>
                      <router-link to="/">其他</router-link>
                    </li>
                    <li>
                      <router-link to="/"></router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <div class="widget-menu-top">
                  <h4 class="title">户型鉴赏</h4>
                  <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <a href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzI4NTQxMDg1Ng==&action=getalbum&album_id=2503386162040946689&scene=173&from_msgid=2247484940&from_itemidx=2&count=3&nolastread=1#wechat_redirect"
                           target="_black">户型图合集</a>
                      </li>
                      <li>
                        <a href="https://fulihaofang.ideamake.cn/icp/fulikaiyuancheng_1078/index.html#" target="_black">开远城云端微世界</a>
                      </li>
                      <li>
                        <a href="https://infishow.ideamake.cn/icp/fulikaiyuankejiguanquanjingshipai_12241/index.html#"
                           target="_black">开远城科技馆实拍</a>
                      </li>
                      <li>
                        <router-link to="#"></router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <h4 class="title">关于我们</h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                    <li>
                      <a href="https://mp.weixin.qq.com/s?__biz=MzI4NTQxMDg1Ng==&mid=2247484940&idx=1&sn=473ca6579e8d2a0885c8dfd151ae2bb5&chksm=ebedd54bdc9a5c5dc70289130a0c97ef8414fe017b505bb5508effe95359ca3c8d56afdd0052#rd" target="_black">微信公众号</a>
                    </li>
                    <li>
                      <a href="https://www.douyin.com/user/MS4wLjABAAAAuAiyPQ961vODnH-hIRBuI8FHmNyqjUN8qT09_YFdPGs"
                         target="_black">抖音号</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <h4 class="title"></h4>
                <div class="inner">
                  <ul class="footer-link link-hover">
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <h4 class="title">守护我们共同的家园</h4>
                <div class="inner">
                  <h6 class="subtitle">4000+户业主一起守护我们美好的家园，开远城将因各位而更加美好。</h6>
                  <ul class="social-icon social-default justify-content-start">
                    <li>
                      <a href="https://www.douyin.com/user/MS4wLjABAAAAuAiyPQ961vODnH-hIRBuI8FHmNyqjUN8qT09_YFdPGs"
                         target="_blank">
                        <Icon name="video"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://mp.weixin.qq.com/s?__biz=MzI4NTQxMDg1Ng==&mid=2247484940&idx=1&sn=473ca6579e8d2a0885c8dfd151ae2bb5&chksm=ebedd54bdc9a5c5dc70289130a0c97ef8414fe017b505bb5508effe95359ca3c8d56afdd0052#rd" target="_blank">
                        <Icon name="file-text"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://kaiyuancheng.com/" target="_blank">
                        <Icon name="chrome"/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter v-if="data.showNewsletter"/>
    </footer>
    <!-- End Footer Area  -->

    <Copyright v-if="data.showCopyright"/>

    <ScrollTop/>
  </div>
</template>

<script>
import Copyright from './Copyright'
import Button from '../../elements/button/Button'
import Icon from '../../icon/Icon'
import ScrollTop from './ScrollTop'
import Logo from '../../elements/logo/Logo'
import Newsletter from './Newsletter'

export default {
  name: 'Footer',
  components: {Newsletter, Logo, ScrollTop, Icon, Button, Copyright},
  props: {
    data: {
      default: null
    }
  }
}
</script>
<template>
  <div class="rn-card box-card-style-default"
       :class="{'card-list-view': blogPostStyle === 'list'}">
    <div class="inner">
      <div class="thumbnail">
        <a class="image" :href="blog.href" target="_blank">
          <img class="w-100" style="height: 14em" :src="blog.img" :alt="blog.title">
        </a>
      </div>
      <div class="content">
        <ul v-if="blogPostStyle === 'card'" class="rn-meta-list">
          <li>
            <router-link :to="''">
              {{ blog.author }}
            </router-link>
          </li>
          <li class="separator">/</li>
          <li>{{ blog.publishedAt }}</li>
        </ul>
        <h4 class="title">
          <a :href="blog.href" target="_blank">{{ blog.title }}</a>
        </h4>
        <ul v-if="blogPostStyle === 'list'" class="rn-meta-list">
          <li>
            <router-link :to="''">
              {{ blog.author }}
            </router-link>
          </li>
          <li class="separator">/</li>
          <li>{{ blog.publishedAt }}</li>
        </ul>
        <p v-if="blogPostStyle === 'list'" class="descriptiion">
          {{ blog.excerpt }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogPost',
  props: {
    blog: {},
    blogPostStyle: {
      type: String,
      default: 'card'
    }
  }
}
</script>
<template>
  <div>
    <div class="row row--15">
      <div class="col-lg-12">
        <ul class="nav nav-pills rwt-portfolio-filter filter-button-default liststyle mb--20"
            id="pills-tab"
            role="tablist"
            data-aos="fade-up">
          <li class="nav-item list-item"
              role="presentation"
              v-for="(filter, index) in filters"
              :key="`tab-button-${filter.id}`">
            <button class="nav-link"
                    :class="{'active': index === 0}"
                    :id="`pills-${filter.text}-tab`"
                    data-bs-toggle="pill"
                    :data-bs-target="`#pills-${filter.text}`"
                    type="button"
                    role="tab"
                    :aria-controls="`pills-${filter.text}`"
                    :aria-selected="index === activeTab ? 'true' : 'false'"
                    @click="activeTab = index">
              {{ filter.text }}
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade"
           :class="{'show active': index === 0}"
           :id="`pills-${filter.text}`"
           role="tabpanel"
           :aria-labelledby="`pills-${filter.text}-tab`"
           v-for="(filter, index) in filters"
           :key="`tab-pane-${filter.id}`">
        <div class="row mt_dec--30 row--15">
          <div v-if="filter.text === 'ALL'" v-for="(gallery, index) in galleryData" :class="column" :key="index">
            <div class="rn-gallery icon-center" data-aos="fade-up">
              <div class="thumbnail">
                <img class="radius-small"
                     :src="gallery.thumb"
                     alt="Video Thumbnail"/>
                <p v-html="gallery.title"></p>
              </div>
              <div class="video-icon">
                <Button variant-button-class="rounded-player sm-size"
                        :is-link="false"
                        @btnClick="imageIndex = index">
                  <span><Icon :name="gallery.type === 'video' ? 'play' : 'zoom-in'" size="30"/></span>
                </Button>
              </div>
            </div>
          </div>
          <div v-if="filter.text !== 'ALL' && (filter.text === gallery.category||gallery.category==='ALL')"
               :class="column" :key="index" v-for="(gallery, index) in galleryData">

            <div class="rn-gallery icon-center" data-aos="fade-up">
              <div class="thumbnail">
                <img class="radius-small"
                     :src="gallery.thumb"
                     alt="Video Thumbnail"/>
                <p v-html="gallery.title"></p>
              </div>
              <div class="video-icon">
                <Button variant-button-class="rounded-player sm-size"
                        :is-link="false"
                        @btnClick="imageIndex = index">
                  <span><Icon :name="gallery.type === 'video' ? 'play' : 'zoom-in'" size="30"/></span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CoolLightBox
          :items="galleryData"
          :index="imageIndex"
          @close="imageIndex = null">
      </CoolLightBox>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'
import Button from '../button/Button'

export default {
  name: 'Gallery',
  components: {Button, Icon},
  props: {
    galleryData: {},
    column: {
      type: String,
      default: 'col-lg-6 col-md-6 col-12 mt--30'
    }
  },
  data() {
    return {
      filters: [
        {
          id: 1,
          text: 'ALL',
        },
        {
          id: 2,
          text: 'A区',
        },
        {
          id: 3,
          text: 'B区',
        },
        {
          id: 4,
          text: 'F区',
        }
      ],
      activeFilter: '',
      activeTab: 0,
      imageIndex: null
    }
  }
}
</script>
<template>
  <Layout header-class="header-left-align" nav-alignment="right" :header-transparency="true" :show-top-header="false"
          buy-button-class="btn-default btn-small">
    <!-- Start Slider Area -->
    <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
      <VueSlickCarousel v-bind="sliderSetting">
        <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
          <div class="height-950 bg-overlay bg_image justify-content-center"
               v-if="slider.type==='video'&&screenWidth>1000">
            <div class="height-950 justify-content-center">
              <video class="height-950" :src="slider.video" muted loop
                     :enable-progress-gesture="false"
                     :controls="false"
                     :show-center-play-btn="false" autoplay
                     style="z-index: 1;display: unset"></video>
              <div class="container position-absolute">
                <div class="row row--30 align-items-center justify-content-center">
                  <div class="order-2 order-lg-1 col-lg-7">
                    <div class="inner text-center">
                      <p class="description" v-html="slider.header"/>
                      <h1 class="title" v-html="slider.title"/>
                      <flipCountdown v-if="!slider.delivered" :deadline="slider.end"
                                     @timeElapsed="timeElapsedHandler"></flipCountdown>
                      <p class="description" v-html="slider.description"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="height-950 bg-overlay bg_image"
               :style="{'background-image': `url(${slider.image})`}">
            <div class="container">
              <div class="row row--30 align-items-center justify-content-center">
                <div class="order-2 order-lg-1 col-lg-7">
                  <div class="inner text-center">
                    <p class="description" v-html="slider.header"/>
                    <h1 class="title" v-html="slider.title"/>
                    <flipCountdown v-if="!slider.delivered" :deadline="slider.end"
                                   @timeElapsed="timeElapsedHandler"></flipCountdown>
                    <p class="description" v-html="slider.description"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <!-- End Slider Area -->

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="关于我们"
                title="守护我们共同的家园"
                description="因为家让我们万人相聚，因为爱让我们一同守护。愿一切美好不期而遇，愿所有的期待都开花结果"
                data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceFive
            service-style="gallery-style"
            text-align="left"
        />
      </div>
    </div>
    <!-- End Service Area -->

    <Separator/>

    <!-- Start Portfolio Area -->
    <div class="rwt-portfolio-area rn-section-gap" id="buildprogress">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="施工进度怎么样"
                title="施工现场视频"
                description="视频来源业主拍摄及网络平台，仅供参考"
                data-aos="fade-up"
            />
          </div>
        </div>
        <Gallery :gallery-data="galleryData" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"/>
      </div>
    </div>
    <!-- End Portfolio Area -->

    <Separator/>

    <!-- Start Testimonial Area  -->
    <div class="rwt-testimonial-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="业主反馈"
                title="业主留言"
                description="希望富力开远程....."
                data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--15">
          <div class="col-lg-4 col-md-6 col-12"
               v-for="(testimonial, index) in testimonialData"
               :key="index">
            <Testimonial :testimonial="testimonial" :testimonial-style="2"/>
          </div>
        </div>
      </div>
    </div>
    <!-- End Testimonial Area  -->

    <Separator/>

    <!-- Start Blog Area -->
    <div class="blog-area rn-section-gap" id="news">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="最新消息"
                title="文章公告"
                description="分享最新的小区资讯，及时掌握最新消息"
                data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--15">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
               data-aos="slide-up"
               data-aos-duration="800"
               :data-aos-delay="100 + index"
               v-for="(blog, index) in posts"
               :key="index"
               v-if="index < 3">
            <BlogPost :blog="blog"/>
          </div>

          <!-- Start Header Btn -->
          <div class="view-more-button mt--35 justify-content-center" style="display: flex">
            <a class="btn-default"
               href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzI4NTQxMDg1Ng==&action=getalbum&album_id=2391934898384338946&scene=173&from_msgid=2247484703&from_itemidx=1&count=3&nolastread=1#wechat_redirect"
               target="_blank">查看更多</a>
          </div>
          <!-- End Header Btn  -->
        </div>
      </div>
    </div>
    <!-- End Blog Area -->
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import Separator from '../../components/elements/separator/Separator'
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPost from '../../components/blog/BlogPost'
import BlogPostMixin from '../../mixins/BlogPostMixin'
import Testimonial from '../../components/elements/testimonial/Testimonial'
import Portfolio from '../../components/elements/portfolio/Portfolio'
import ServiceFive from '../../components/elements/service/ServiceFive'
import Progressbar from '../../components/elements/progressbar/Progressbar'
import Button from '../../components/elements/button/Button'
import Video from "@/components/elements/video/Video";
import Gallery from "@/components/elements/gallery/Gallery";
import flipCountdown from "vue2-flip-countdown";

export default {
  name: 'DigitalAgency',
  components: {
    flipCountdown,
    Gallery,
    Button, Video,
    Progressbar,
    ServiceFive,
    Portfolio,
    Testimonial,
    BlogPost,
    Separator,
    SectionTitle,
    Layout,
    VueSlickCarousel
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽度
      screenHeight: document.body.clientHeight, // 屏幕高度
      sliderSetting: {
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        autoplaySpeed: 50000,
        slidesToScroll: 1,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/img/about.png',
          video: 'https://infishow.ideamake.cn/icp/fulikaiyuankejiguanquanjingshipai_12241/kaiyuancheng_5548/images/200909110143avkkf.mp4?timestamp=1599620512949',
          type: 'video',
          header: '欢迎来到',
          title: '富力开远城业主之家',
          end: '2022-07-01 00:00:00',
          delivered: true,
          description: '进群请点击联系我们'
        }, {
          type: 'image',
          image: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/img/banner-1-a.png',
          header: '距离A区交付还剩',
          title: 'A区已延期交付',
          end: '2022-07-01 00:00:00',
          delivered: false,
          description: ''
        },
        {
          type: 'image',
          image: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/img/banner-2-b.png',
          header: '距离B区交付还剩',
          title: 'B区施工中',
          delivered: false,
          end: '2023-07-01 00:00:00',
          description: ''
        },
        {
          type: 'image',
          image: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/img/banner-3-f.png',
          header: 'F区已经交付',
          title: 'F区已交付',
          delivered: true,
          end: '2021-06-01 00:00:00',
          description: '立足今日，我们擦亮眼睛，走过昨日逝去的岁月，点燃新的希望，放飞新的梦想'
        },
      ],
      testimonialData: [
        {
          image: 'WechatIMG294',
          name: '最美逆行者',
          designation: '富力开远城B区业主',
          location: 'CO Miego, AD, USA',
          description: '“愿富力开远城B区保质保量如期交付„',
        },
        {
          image: 'WechatIMG342',
          name: '温暖的家',
          designation: '富力开远城B区业主',
          location: 'Bangladesh',
          description: '“愿祖国繁荣昌盛，人民生活蒸蒸日上„',
        },
        {
          image: 'WechatIMG9421',
          name: '小学生',
          designation: '富力开远城B区业主',
          location: 'Poland',
          description: '“ 好好学习，天天向上，家速向前，美好将至„',
        },
      ],
      galleryData: [
        {
          title: '2022.07.22 莲湖区公共卫生服务中心',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.22%20%E8%8E%B2%E6%B9%96%E5%8C%BA%E5%85%AC%E5%85%B1%E5%8D%AB%E7%94%9F%E6%9C%8D%E5%8A%A1%E4%B8%AD%E5%BF%83.png',
          category: 'ALL',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.22%20%E8%8E%B2%E6%B9%96%E5%8C%BA%E5%85%AC%E5%85%B1%E5%8D%AB%E7%94%9F%E6%9C%8D%E5%8A%A1%E4%B8%AD%E5%BF%83.mp4',
          type: 'video'
        },
        {
          title: '2022.07.20莲湖区第一学校（联合铁一中办校）',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.20%E8%8E%B2%E6%B9%96%E5%8C%BA%E7%AC%AC%E4%B8%80%E5%AD%A6%E6%A0%A1%EF%BC%88%E8%81%94%E5%90%88%E9%93%81%E4%B8%80%E4%B8%AD%E5%8A%9E%E6%A0%A1%EF%BC%89.png',
          category: 'ALL',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.20%E8%8E%B2%E6%B9%96%E5%8C%BA%E7%AC%AC%E4%B8%80%E5%AD%A6%E6%A0%A1%EF%BC%88%E8%81%94%E5%90%88%E9%93%81%E4%B8%80%E4%B8%AD%E5%8A%9E%E6%A0%A1%EF%BC%89.mp4',
          type: 'video'
        },
        {
          title: '2022.07.17 富力开远城F实拍',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.17%20%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EF%E5%AE%9E%E6%8B%8D.png',
          category: 'F区',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.17%20%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EF%E5%AE%9E%E6%8B%8D.mp4',
          type: 'video'
        },
        {
          title: '2022.07.14富力开远城B区7月工程播报',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.14%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA7%E6%9C%88%E5%B7%A5%E7%A8%8B%E6%92%AD%E6%8A%A5.png',
          category: 'ALL',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.14%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA7%E6%9C%88%E5%B7%A5%E7%A8%8B%E6%92%AD%E6%8A%A5.mp4',
          type: 'video'
        },
        {
          title: '2022.07.01 富力开远城业主庆祝建党101周年',
          category: 'B区',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.01%20%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8E%E4%B8%9A%E4%B8%BB%E5%BA%86%E7%A5%9D%E5%BB%BA%E5%85%9A101%E5%91%A8%E5%B9%B4.png',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.01%20%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8E%E4%B8%9A%E4%B8%BB%E5%BA%86%E7%A5%9D%E5%BB%BA%E5%85%9A101%E5%91%A8%E5%B9%B4.mp4',
          type: 'video'
        }, {
          title: '2022.06.30富力开远城B区6月家书',
          category: 'ALL',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.30%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA6%E6%9C%88%E5%AE%B6%E4%B9%A6.png',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.30%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA6%E6%9C%88%E5%AE%B6%E4%B9%A6.mp4',
          type: 'video'
        },
        {
          title: '2022.06.16富力开远城B区工地开放日',
          category: 'B区',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.16%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA%E5%B7%A5%E5%9C%B0%E5%BC%80%E6%94%BE%E6%97%A5.png',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.16%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA%E5%B7%A5%E5%9C%B0%E5%BC%80%E6%94%BE%E6%97%A5.mp4',
          type: 'video'
        },
        {
          title: '2022.06.15 富力开远城工地实拍',
          category: 'B区',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.15%20%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8E%E5%B7%A5%E5%9C%B0%E5%AE%9E%E6%8B%8D.png',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.15%20%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8E%E5%B7%A5%E5%9C%B0%E5%AE%9E%E6%8B%8D.mp4',
          type: 'video'
        },
        {
          title: '2022.06.04施工进展',
          category: 'B区',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.04%E6%96%BD%E5%B7%A5%E8%BF%9B%E5%B1%95.png',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.06.04%E6%96%BD%E5%B7%A5%E8%BF%9B%E5%B1%95.mp4',
          type: 'video'
        },
        {
          category: 'B区',
          title: '2022.04.20开远城B区航拍视频',
          thumb: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.04.20%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA%E8%88%AA%E6%8B%8D%E8%A7%86%E9%A2%91.png',
          src: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.04.20%E5%BC%80%E8%BF%9C%E5%9F%8EB%E5%8C%BA%E8%88%AA%E6%8B%8D%E8%A7%86%E9%A2%91.mp4',
          type: 'video'
        },
      ],
    }
  }, mounted() {
    setInterval(this.changeDate(), 1000);
  }, methods: {
    changeDate() {
      this.sliderData.forEach((item, index) => {
      })
    },
    timeElapsedHandler() {
      alert("已延期交付")
    }
  }
}
</script>
<template>
  <Layout header-class="header-transparent" :show-newsletter="false" :show-cta="false" :header-transparency="true"
          :show-top-header="false">
    <!-- Start Slider Area -->
    <div class="slider-area slider-style-1 height-850 bg_image"
         data-black-overlay="7"
         :style="{'background-image': `url(https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/img/about.png)`}">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <div>
                <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                  <span class="theme-gradient">关于我们</span>
                </h3>
              </div>
              <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                这里是<br/>开远城业主之家
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Service Area -->
    <div class="service-area rn-section-gapTop">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="content" data-aos="fade-up">
              <h3 class="title">富力开远城</h3>
            </div>
          </div>
          <div class="col-lg-8">
            <p class="mb--10" data-aos="fade-up">
              富力集团以全球化CBD运营经验，战略落子大西安，于大西安CAZ活力中心打造百万方综合体——富力开远城<br/><br/>

              项目总规划占地约660亩，涵盖多种业态，包括高品质住宅、国际超高层办公、51万方甲级写字楼集群、9万方大型Shopping
              Mall、11万方星级酒店及34万方国际公寓，建成之后不仅将重绘一个区域的文明天际，更将左右一座城市的中心所向。<br/>
              同时，富力开远城还将以大手笔、高规格打造西北超高双子塔，以278米超高层办公和238米超高层公寓，重塑一座城市的精神海拔。<br/><br/>

              在社区内部空间的打造上，项目针对不同年龄段构建分区互不干扰的活动空间，如老人太极拳、下棋的场所，儿童游乐活动场所，为年轻人打造球类活动场地等。<br/>
              再配合着亲水平台、观湖景亭、小飞虹、山水屏障和石涧跌水等景观设计，为业主营造一个亲近自然的休闲场所，让业主的归家之路仿佛置身于一场花园旅行中。
              能够看出，在这方极具天赋的土地上，富力正以“城市运营商”的理念，打造出一座世界级的城市综合体，将万千繁盛，汇于一城！<br/><br/>

              超高层建筑设置有泛会所，包括有室内游乐园、私人健身房、高级会客厅等，无论是陪孩子度过一个悠闲的周末时间，还是和家人一起在私人健身房里跑跑步，做做瑜伽，甚至是邻里之间聊个事，都方便极了！
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Service Area -->

    <!-- Start Brand Area -->
    <!--        <div class="rwt-brand-area pb&#45;&#45;60 pt&#45;&#45;30">-->
    <!--            <div class="container">-->
    <!--                <div class="row">-->
    <!--                    <div class="col-lg-12 mt&#45;&#45;10">-->
    <!--                        <Brand :brand-list="brandList" :brand-style="3"/>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!-- End Brand Area -->

    <Separator/>

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="我们正在做什么"
                title="为了更好地监督施工生产"
                description="欢迎各位业主加入业主群，一起守护我们美好的家园！"
                data-aos="fade-up"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End Service Area -->

    <div class="rwt-counterup-area pb--100">
      <div class="container mt_dec--30">
        <Counter
            :counter-style="5"
            text-align="center"
            :counter-data="counterData"
            column="col-lg-3 col-md-6 col-sm-6 col-12"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Separator from '../components/elements/separator/Separator'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import AboutFour from '../components/elements/about/AboutFour'
import ServiceOne from '../components/elements/service/ServiceOne'
import Timeline from '../components/elements/timeline/Timeline'
import Counter from '../components/elements/counterUp/Counter'
import Brand from '../components/elements/brand/Brand'
import Team from '../components/elements/team/Team'

export default {
  name: 'About',
  components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout},
  data() {
    return {
      brandList: [
        {
          image: require(`@/assets/images/brand/brand-01.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-02.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-03.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-04.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-05.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-06.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-07.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-08.png`)
        },
        {
          image: require(`@/assets/images/brand/brand-01.png`)
        },

      ],
      timelineData: [
        {
          id: '1',
          title: 'Knowledge',
          description: 'Present all available features in Essentials.',
        },
        {
          id: '2',
          title: 'Working',
          description: 'All Feature available features in Essentials.',
        },
        {
          id: '3',
          title: 'Solution',
          description: 'Popular Feature available features in Essentials.',
        },
        {
          id: '4',
          title: 'Process',
          description: 'Latest Feature available features in Essentials.',
        },
      ],
      counterData: [
        {
          number: 1000,
          title: '已经取得联系业主数',
        },
        {
          number: 575,
          title: '授权监督生产业主数',
        },
        {
          number: 5,
          title: '已与开发商协商次数',
        },
        {
          number: 0,
          title: '已复工天数',
        }
      ],
      teamData: [
        {
          image: 'team-dark-01',
          name: 'Sr Janen Sara',
          designation: 'Sr Product Designer',
          location: 'CO Miego, AD, USA',
          description: 'Yes, I am a product designer. I have a passion for product design.'
        },
        {
          image: 'team-dark-02',
          name: 'Corporate Jane',
          designation: 'Manager',
          location: 'Bangladesh',
          description: 'Yes, I am a product designer. I have a passion for product design.'
        },
        {
          image: 'team-dark-03',
          name: 'Jara Saraif',
          designation: 'Software Developer',
          location: 'Poland',
          description: 'Yes, I am a product designer. I have a passion for product design.'
        },
        {
          image: 'team-dark-04',
          name: 'Afanan Sifa',
          designation: 'Accounts Manager',
          location: 'Poland',
          description: 'Yes, I am a product designer. I have a passion for product design.'
        },
      ]
    }
  },
  mounted() {
    let day = new Date().getTime()
    let start = 1655136000 * 1000;
    this.counterData[3].number = (day - start) / 1000 / 3600 / 24;
  }
}
</script>
<template>
  <Layout :show-newsletter="false" :show-cta="false" :header-transparency="true"
          :show-top-header="false">    <!-- Start Contact Area  -->
    <div class="rwt-contact-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--40">
            <SectionTitle
                text-align="center"
                subtitle="联系我们"
                title="您可以通过如下任意一种形式联系我们，并加入业主群"
            />
          </div>
        </div>
        <div class="row row--15">
          <div class="col-lg-12">
            <div class="rn-contact-address mt_dec--30">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                  <div class="rn-address">
                    <div class="icon">
                      <Icon name="message-square" :size="40"/>
                    </div>
                    <div class="inner">
                      <h4 class="message-square">业主群</h4>
                      <p><a
                          href="https://mp.weixin.qq.com/s?__biz=MzI4NTQxMDg1Ng==&mid=2247484940&idx=1&sn=473ca6579e8d2a0885c8dfd151ae2bb5&chksm=ebedd54bdc9a5c5dc70289130a0c97ef8414fe017b505bb5508effe95359ca3c8d56afdd0052#rd"
                          target="_blank">点击进入业主群</a></p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <div class="rn-address">
                    <div class="icon">
                      <Icon name="at-sign" :size="40"/>
                    </div>
                    <div class="inner">
                      <h4 class="title">站长微信</h4>
                      <p><a href="#">kaiyuancheng2020</a></p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-12">
                  <div class="rn-address">
                    <div class="icon">
                      <Icon name="video" :size="40"/>
                    </div>
                    <div class="inner">
                      <h4 class="title">抖音</h4>
                      <p><a href="https://www.douyin.com/user/MS4wLjABAAAAuAiyPQ961vODnH-hIRBuI8FHmNyqjUN8qT09_YFdPGs"
                            target="_blank">这里是开远城</a>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 col-12">
                  <div class="rn-address">
                    <div class="icon">
                      <Icon name="file-text" :size="40"/>
                    </div>
                    <div class="inner">
                      <h4 class="title">微信公众号</h4>
                      <p><a href="https://mp.weixin.qq.com/s/vGyVKHgKUcv80NvFByLmEQ" target="_blank">这里是开远城</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt--40 row--15">
          <div class="col-lg-12 mt_md--30 mt_sm--30">
            <GoogleMap/>
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

export default {
  name: 'ContactPage',
  components: {ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout}
}
</script>
import AppFunctions from '../helpers/AppFunctions'

export default {
    data() {
        return {
            posts: [
                {
                    img: require(`@/assets/images/bg/fuli-a.jpg`),
                    author: '这里是开远城',
                    authorId: '1',
                    view: '700 views',
                    publishedAt: '2022-07-15',
                    title: '市政府印发防范商品房延期交房问题的通知',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: ``,
                    href: 'https://mp.weixin.qq.com/s?__biz=MzI4NTQxMDg1Ng==&mid=2247484816&idx=1&sn=53ae955f8202206537834ff60d898df1&chksm=ebedd6d7dc9a5fc162ba815d070299f17a7f314d34ed27f30f869e9a3dfe9e84fe3d95333ecd#rd',
                    categories: ['Development', 'Company', 'Marketing'],
                    tags: ['Corporate', 'Agency', 'Creative', 'Design']
                },
                {
                    img: 'https://guoyi-1253518559.cos.ap-shanghai.myqcloud.com/video/2022.07.01%20%E5%AF%8C%E5%8A%9B%E5%BC%80%E8%BF%9C%E5%9F%8E%E4%B8%9A%E4%B8%BB%E5%BA%86%E7%A5%9D%E5%BB%BA%E5%85%9A101%E5%91%A8%E5%B9%B4.png',
                    author: '这里是开远城',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '2022-07-01',
                    title: '富力开远城B区全体业主庆祝党成立101周年',
                    href: "https://mp.weixin.qq.com/s?__biz=MzI4NTQxMDg1Ng==&mid=2247484703&idx=1&sn=9c257aab5890b250a68df42c41cb0209&chksm=ebedd658dc9a5f4e219b1aeca366ae38d49904745bfbfe1a164a7c09d61260fd38e7067fecd7#rd",
                    excerpt: 'We have always known the power of digital we\'ve.',
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                },
                {
                    img: require(`@/assets/images/bg/fuli-b.jpg`),
                    author: '这里是开远城',
                    authorId: '2',
                    view: '1020 查看',
                    publishedAt: '2022-06-15',
                    title: '06-15关于开远城B区施工进度及授权委托的公告',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    href: 'https://mp.weixin.qq.com/s?__biz=MzI4NTQxMDg1Ng==&mid=2247484770&idx=3&sn=20b6aefcaa0e649a39ab4a187eb3a836&chksm=ebedd625dc9a5f3318d50e425bae7ba4b5f8263f43ce825fd1d6db038120770dcfc5c3b581fb#rd',
                    categories: ['UX Design', 'Company'],
                    tags: ['Minimal', 'Company', 'Development', 'Design']
                },
                {
                    img: require(`@/assets/images/blog/blog-04.jpg`),
                    author: '这里是开远城',
                    authorId: '4',
                    view: '70 views',
                    publishedAt: '25 Aug 2022',
                    title: 'Top 5 Lessons About Corporate To Learn Before.',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    categories: ['Marketing', 'UX Design', 'Business'],
                    tags: ['App', 'Business', 'Software', 'Design']
                },
            ],
            categories: '',
            tags: '',
            AppFunctions
        }
    },
    methods: {
        blogCategories() {
            let cats = this.posts.map(item => {
                    return item.categories;
                }),
                singleCatArray = AppFunctions.flatDeep(cats),
                categories = [];

            singleCatArray.forEach(cat => {
                const obj = {
                    title: cat.trim(),
                    slug: AppFunctions.slugify(cat),
                    count: 1
                }
                const objIndex = AppFunctions.containsObject(obj, categories);
                if (objIndex !== -1) {
                    const prevCount = categories[objIndex].count;
                    categories[objIndex] = {
                        title: cat.trim(),
                        slug: AppFunctions.slugify(cat),
                        count: prevCount + 1
                    }
                } else {
                    categories.push(obj);
                }
            });

            this.categories = categories;
        },
        blogTags() {
            let tags = this.posts.map(item => {
                    return item.tags;
                }),
                singleTagArray = AppFunctions.flatDeep(tags),
                tagsData = [];

            singleTagArray.forEach(tag => {
                const obj = {
                        title: tag.trim(),
                        slug: AppFunctions.slugify(tag)
                    },
                    objIndex = AppFunctions.containsObject(obj, tagsData);

                if (objIndex !== -1) {
                    tagsData[objIndex] = {
                        title: tag.trim(),
                        slug: AppFunctions.slugify(tag)
                    }
                } else {
                    tagsData.push(obj);
                }
            });

            this.tags = tagsData;
        }
    },
    created() {
        this.blogCategories();
        this.blogTags();
    }
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About'
import BlogGridPage from '../views/BlogGridPage'
import BlogDetailsPage from '../views/BlogDetailsPage'
import TimelinePage from '../views/TimelinePage'
import ContactPage from '../views/ContactPage'
import ErrorPage from '../views/404'
import Home from '../views/home-pages/Home'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '富力开远城-业主之家官网',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们 || 富力开远城-业主之家官网',
        },
    },
    {
        path: '/blog-grid',
        name: 'Blog grid',
        component: BlogGridPage,
        meta: {
            title: '博客 || 富力开远城-业主之家官网',
        },
    },
    {
        path: '/blog-details/:id',
        name: 'Blog details',
        component: BlogDetailsPage,
        meta: {
            title: '博客 || 富力开远城-业主之家官网',
        },
    },
    {
        path: '/timeline',
        name: 'Timeline',
        component: TimelinePage,
        meta: {
            title: 'Timeline || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: '联系我们 || 富力开远城-业主之家官网',
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '404 || 富力开远城-业主之家官网',
        },
    },
    {
        path: '/blog-details',
        name: 'Blog details',
        component: BlogDetailsPage,
        meta: {
            title: 'Blog Details || Doob Business and Consulting Bootstrap5 Template',
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
